<template>
  <div
    class="pt-1"
    style="text-align: left; max-height: 510px; overflow-y: auto"
  >
    <tr
      v-for="(benefit, index) in benefits"
      :key="`benefit-${index}`"
      class="d-flex align-baseline mt-4 mb-3"
    >
      <td width="42">
        <v-icon size="24" :color="benefit.color" class="me-5">{{
          benefit.icon
        }}</v-icon>
      </td>
      <td>{{ $t(benefit.textKey) }}</td>
    </tr>
  </div>
</template>

<script>
export default {
  name: "BenefitsList",

  props: {
    benefits: { type: Array, default: () => [] },
  },
};
</script>
