<template>
  <div>
    <v-card outlined @click="editCompanyProfile = true">
      <Alert v-if="doNotHaveCNPJ" color="error" class="mx-2 mt-2 mb-0" dense>
        <span v-html="$t('companyInformation.missingCNPJAlert')"></span>
      </Alert>
      <v-list-item link class="pa-0 ma-0">
        <v-row class="ma-0 py-2 px-1">
          <v-col
            cols="12"
            class="py-2"
            v-for="(info, index) in information"
            :key="index"
          >
            <div
              :class="`px-0 font-weight-medium caption text-wrap ${
                !info.emptyValue ? 'error--text' : ''
              }`"
              v-text="info.title"
            ></div>
            <div
              :class="`px-0 text-body-2 ${
                !info.emptyValue ? 'error--text font-weight-medium' : ''
              }`"
            >
              {{ info.value }}
            </div>
          </v-col>
        </v-row>
        <v-list-item-action class="pr-4">
          <v-icon>mdi-pencil</v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-card>

    <!-- COMPANY PROFILE FORM DIALOG -->
    <SimpleDialog
      :title="$t('companyInformation.billingInfo')"
      :show="editCompanyProfile"
      @close="closeCompanyProfileEditor"
      read-only
      width="800"
    >
      <template v-slot:body>
        <CompanyProfileForm
          :isSelfCheckout="true"
          :company-profile="company"
          @close="closeCompanyProfileEditor"
        />
      </template>
    </SimpleDialog>

    <!-- ADVICE NO PROFILE COMPANY PERMISSION DIALOG -->
    <AdvicePermissionDialog
      :show="showNotAllowedAdvice"
      @close="showNotAllowedAdvice = false"
      :title="$t('advicePermissionDialog.companyInfoTitle')"
      :message="$t('advicePermissionDialog.companyInfoMessage')"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { maskCNPJ } from "@/helpers/services/utils";

import AdvicePermissionDialog from "@/components/home/dialogs/AdvicePermissionDialog";
import CompanyProfileForm from "@/components/billing/CompanyProfileForm";

export default {
  name: "CompanyInformation",

  components: { AdvicePermissionDialog, CompanyProfileForm },

  data() {
    return {
      editCompanyProfile: false,
      showNotAllowedAdvice: false,
    };
  },

  computed: {
    ...mapGetters(["company", "doNotHaveCNPJ", "hasProfilePermission"]),

    cnpjNumber() {
      return this.company.cnpj;
    },

    corporateName() {
      return this.company.corporate_name;
    },

    financialEmail() {
      return this.company.financial_email;
    },

    information() {
      return [
        {
          title: "CNPJ",
          emptyValue: !!this.cnpjNumber,
          value: this.cnpjNumber
            ? maskCNPJ(this.cnpjNumber)
            : this.$t("common.notRegistered"),
        },
        {
          title: this.$t("companyProfile.corporate"),
          emptyValue: !!this.corporateName,
          value: this.corporateName || this.$t("common.notRegistered"),
        },
        {
          title: this.$t("companyProfile.financeEmail"),
          emptyValue: !!this.financialEmail,
          value: this.financialEmail || this.$t("common.notRegistered"),
        },
      ];
    },
  },

  methods: {
    maskCNPJ,

    closeCompanyProfileEditor() {
      this.editCompanyProfile = false;
    },

    openCompanyProfileForm() {
      if (this.hasProfilePermission) {
        this.editCompanyProfile = true;
      } else {
        this.showNotAllowedAdvice = true;
      }
    },
  },
};
</script>
