<template>
  <v-card outlined v-if="selfCheckoutPlan">
    <v-list-item>
      <v-list-item-content class="pb-0">
        <v-list-item-title>
          <v-row class="ma-0 pa-0 font-weight-medium" dense align="center">
            <v-col
              v-if="planName"
              cols="10"
              class="text-h5 font-weight-medium px-0"
            >
              {{ planName }}
            </v-col>
            <v-col cols="2" class="d-flex justify-end px-0">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="$emit('clean:selfcheckout-cart')"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon v-text="'mdi-trash-can-outline'" />
                  </v-btn>
                </template>
                <span> {{ $t("selfCheckout.removePlanFromCart") }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-list-item-title>

        <v-row class="ma-0 pa-0" align="center">
          <v-col
            :cols="12"
            xl="4"
            lg="6"
            sm="6"
            xs="12"
            class="px-0"
            v-if="isBasicFreeSelected"
          >
            <v-card-text class="px-0 mx-0">
              <label
                class="caption font-weight-medium text-center text--lighten-2 mx-1"
              >
                {{ $t("hirePlan.paymentPlan") }}
              </label>
              <v-chip-group
                mandatory
                active-class="primary lighten-1 white--text"
                column
                outlined
                class="font-weight-medium"
              >
                <v-chip filter> {{ $t("common.free") }} </v-chip>
              </v-chip-group>
            </v-card-text>
          </v-col>
          <v-col :cols="12" xl="4" lg="6" sm="6" xs="12" class="px-0" v-else>
            <SubscriptionTypeSelector label="hirePlan.paymentPlan" />
          </v-col>
          <v-col
            cols="12"
            xl="8"
            lg="6"
            sm="6"
            xs="12"
            :class="`
                ${$vuetify.breakpoint.mdAndUp ? `` : ''} 
              `"
          >
            <ButtonNumberIncrementer
              label="common.licenseNumber"
              :disabled="
                selfCheckoutSubscriptionType === SUBSCRIPTIONS.FLEX ||
                isBasicFreeSelected
              "
              :label-class="
                $vuetify.breakpoint.mdAndUp ? `d-flex justify-center pl-1` : ''
              "
              :value="selfCheckoutMaxLicenses"
              :min="minLicenses"
              color="primary lighten-2"
              @update="setNewLicensesNumber"
            />
          </v-col>
        </v-row>

        <v-row v-if="usersNumber < MIN_LICENSES">
          <Alert :prominent="false" class="" color="warning" dense :width="800">
            {{ $t("selfCheckout.minLicensesInfo") }}
          </Alert>
        </v-row>

        <v-card-actions
          v-if="selfCheckoutPriceByPeriod"
          class="ma-0 border-top border-bottom pt-4 px-0 flex-column justify-start align-start"
        >
          <v-list-item class="px-0">
            <v-list-item-content class="px-0">
              <v-list-item-title class="py-0">
                <PriceByUserPerSubscription inline />
              </v-list-item-title>
              <v-list-item-subtitle v-if="$vuetify.breakpoint.xs">
                <span class="text-body-2">
                  {{
                    `${$t("common.user")}/${
                      selfCheckoutSubscriptionType == "FLEX"
                        ? $t("periodicity.month")
                        : $t("periodicity.year")
                    }`
                  }}
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card-actions>
        <v-list-item class="px-0" v-if="selfCheckoutService">
          <v-list-item-content class="px-0">
            <v-checkbox
              v-model="hasIntrestInService"
              @click="logInterestInService"
              color="#5f4e7a"
              class="mb-0 pb-0"
              hide-details
            >
              <template v-slot:label>
                <div>
                  <span class="font-weight-bold black--text">
                    {{ $t("selfCheckout.agileImplementation") }}
                  </span>
                  -
                  <span style="color: #53b257" class="font-weight-medium"
                    >{{
                      `${selfCheckoutService.name.split("-")[1]} ${$t(
                        "common.for"
                      )}
                    `
                    }}
                    <v-chip color="success" style="height: 22px">{{
                      formatMoney(selfCheckoutService.price)
                    }}</v-chip></span
                  >
                </div>
              </template>
            </v-checkbox>
            <v-expansion-panels flat class="pt-0 ml-1">
              <v-expansion-panel>
                <v-expansion-panel-header class="ml-1">
                  <span>
                    {{
                      $t("selfCheckout.checkServiceDescription", {
                        name: selfCheckoutService.name.split("-")[1],
                      })
                    }}
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list class="pa-0">
                    <v-list-item
                      class="px-0"
                      v-for="(item, i) in serviceDescription"
                      :key="i"
                    >
                      <v-icon
                        v-text="item.icon ? item.icon : 'mdi-check'"
                        :color="item.icon ? 'black' : 'success'"
                        size="20"
                      />
                      <span
                        :class="`${
                          item.textBold ? 'font-weight-bold ml-1' : 'ml-2'
                        } text-body-2`"
                        >{{
                          $t(
                            `selfCheckout.serviceDescriptions.${item.descriptionKey}`
                          )
                        }}</span
                      >
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { getAnalytics, logEvent } from "firebase/analytics";
import moment from "moment";
import { formatMoney } from "@/helpers/services/utils";
import { translatePeriods } from "@/helpers/variables/translateString";
import {
  SUBSCRIPTIONS,
  STANDARD_SKU,
  MIN_LICENSES,
} from "@/helpers/variables/backendConstants";
import {
  standardService,
  securityService,
} from "@/helpers/variables/productsDetails";

import ButtonNumberIncrementer from "@/components/base/ButtonNumberIncrementer.vue";
import PriceByUserPerSubscription from "@/components/purchase/selfcheckout/PriceByUserPerSubscription.vue";
import SubscriptionTypeSelector from "@/components/billing/checkout/SubscriptionTypeSelector.vue";

export default {
  name: "PlanInCartCard",

  components: {
    ButtonNumberIncrementer,
    PriceByUserPerSubscription,
    SubscriptionTypeSelector,
  },

  data() {
    return {
      SUBSCRIPTIONS,
      translatePeriods,
      standardService,
      securityService,
      MIN_LICENSES,
    };
  },

  computed: {
    ...mapGetters([
      "selfCheckoutSubscriptionType",
      "selfCheckoutPriceByPeriod",
      "selfCheckoutPlan",
      "selfCheckoutMaxLicenses",
      "usersNumber",
      "isBasicFreeSelected",
      "isIntrestedInService",
      "company",
      "selfCheckoutService",
    ]),

    minLicenses() {
      return this.usersNumber < this.MIN_LICENSES
        ? this.MIN_LICENSES
        : this.usersNumber;
    },

    planName() {
      return this.selfCheckoutPlan ? this.selfCheckoutPlan.name : "";
    },

    implementationHint() {
      if (!this.selfCheckoutPlan) return "";
      return this.selfCheckoutPlan.sku == STANDARD_SKU
        ? this.$t("selfCheckout.standardImplementationHint")
        : this.$t("selfCheckout.securityImplementationHint");
    },

    hasIntrestInService: {
      get() {
        return this.isIntrestedInService;
      },
      set(value) {
        this.setIntrestInService(value);
      },
    },

    serviceDescription() {
      if (!this.selfCheckoutPlan) return [];
      return this.selfCheckoutPlan.sku == STANDARD_SKU
        ? standardService
        : [...standardService, ...securityService];
    },
  },

  methods: {
    ...mapMutations(["setSelfCheckoutMaxLicenses", "setIntrestInService"]),

    formatMoney,

    setNewLicensesNumber(number) {
      this.setSelfCheckoutMaxLicenses(number);
    },

    logInterestInService() {
      const { main_domain } = this.company;
      const analytics = getAnalytics();

      logEvent(analytics, "requires-suite-implementation", {
        main_domain,
        clicked_where: "checkbox self-checkout",
        date: moment().format("DD/MM/YYYY"),
        interest: this.hasIntrestInService,
      });
    },
  },
};
</script>
