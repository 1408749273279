<template>
  <div class="d-flex justify-center">
    <div
      v-for="(availablePlan, index) in availablePlans"
      :key="index"
      :class="`mx-2 rounded-lg outlined overflow-hidden align-center ${
        index === 2 && !checkIfClientHasThisPlan(availablePlan.key)
          ? 'deep-purple lighten-5'
          : ''
      }`"
    >
      <UpgradePlanCard
        :subscription-type="subscriptionType"
        :licenses="updatedLicenses"
        :client-has-this-plan="!!checkIfClientHasThisPlan(availablePlan.key)"
        :plan="availablePlan"
        :index="index"
        @select-plan="$emit('select-plan', $event)"
      />

      <v-divider></v-divider>

      <PlanDescription
        :clientHasThisPlan="!!checkIfClientHasThisPlan(availablePlan.key)"
        :plan="availablePlan"
      />
    </div>
  </div>
</template>
<script>
import { getAnalytics, logEvent } from "firebase/analytics";
import { STATUS } from "@/helpers/variables/backendConstants.js";
import { FEATURED_SKU } from "@/helpers/variables/modules";
import {
  productsFeatures,
  productsByRankPlan,
} from "@/helpers/variables/productsDetails";
import { mapGetters, mapMutations } from "vuex";

import UpgradePlanCard from "@/components/billing/UpgradePlanCard";
import PlanDescription from "@/components/billing/PlanDescription";

export default {
  name: "PlanFeaturesExpasion",

  components: {
    UpgradePlanCard,
    PlanDescription,
  },

  props: {
    planRank: { type: Number, default: 0 },
    product: { type: String, required: 0 },
    subscriptionType: { type: String, required: true },
    licenses: { type: Number, default: 0 },
  },

  data() {
    return {
      FEATURED_SKU,
      productsFeatures,
      productsByRankPlan,
    };
  },

  computed: {
    ...mapGetters([
      "plans",
      "contractedPlans",
      "selfCheckoutSubscriptionType",
      "selfCheckoutMaxLicenses",
      "currentUser",
      "mainDomain",
      "hasBasicFreeActive",
    ]),

    updatedMaxLicenses: {
      get() {
        return this.selfCheckoutMaxLicenses;
      },
      set(newVal) {
        this.setSelfCheckoutMaxLicenses(newVal);
      },
    },

    updatedLicenses() {
      return this.licenses;
    },

    products() {
      return Object.keys(productsFeatures);
    },

    hasThisProduct() {
      return productsByRankPlan[this.planRank].includes(this.product);
    },

    availablePlans() {
      return this.plans
        .filter((plan) => plan.is_active && plan.is_visible)
        .sort(function (a, b) {
          if (a.short_code === "conecta_suite") {
            return -1;
          }
          if (b.short_code === "conecta_suite") {
            return 1;
          }
          if (a.price < b.price) {
            return -1;
          }
          if (a.price > b.price) {
            return 1;
          }
          return 0;
        });
    },
  },

  watch: {
    updatedMaxLicenses() {
      const analytics = getAnalytics();

      logEvent(analytics, "self-checkout", {
        click: "Troca de número de licenças",
        main_domain: this.mainDomain,
        email: this.currentUser.email,
        description:
          "Troca de número de licenças na modal de troca de licenças da página de self-checkout",
      });
    },
  },

  methods: {
    ...mapMutations(["setSelfCheckoutMaxLicenses"]),

    checkIfClientHasThisPlan(key) {
      const activePlan = this.contractedPlans.find(
        (plan) =>
          plan.plan.key === key &&
          [STATUS.TRIAL, STATUS.ACTIVE].includes(plan.valid_status) &&
          this.selfCheckoutSubscriptionType == plan.subscription_type
      );

      if (activePlan) {
        return activePlan.valid_status === STATUS.ACTIVE &&
          activePlan.subscription_type === this.selfCheckoutSubscriptionType
          ? "current-plan"
          : true;
      }
      if (this.hasBasicFreeActive?.plan.key === key) {
        return this.hasBasicFreeActive.valid_status === STATUS.ACTIVE &&
          this.hasBasicFreeActive.subscription_type === STATUS.FREE
          ? "current-plan"
          : true;
      }
      return "";
    },
  },
};
</script>
<style scoped></style>
