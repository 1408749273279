<template>
  <v-card-text class="px-0 mx-0">
    <label
      v-if="label"
      class="caption font-weight-medium text-center text--lighten-2 mx-1"
    >
      {{ $t(label) }}
    </label>
    <v-chip-group
      v-model="subscriptionType"
      mandatory
      active-class="primary lighten-1 white--text"
      column
      outlined
      class="font-weight-medium"
    >
      <v-chip
        filter
        v-for="chip in subscriptionOptions"
        :key="chip.value"
        :value="chip.value"
      >
        {{ chip.name }}
      </v-chip>
    </v-chip-group>
  </v-card-text>
</template>
<script>
import { getAnalytics, logEvent } from "firebase/analytics";
import { SUBSCRIPTIONS } from "@/helpers/variables/backendConstants.js";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "SubscriptionTypeSelector",

  props: {
    label: {
      type: String,
      default: "selfCheckout.choosePlanPaymentType",
    },
    enableTrial: { type: Boolean, default: false },
  },

  data() {
    return {
      subscriptionsTypes: [
        {
          name: this.$t("common.freeTrialPeriod"),
          value: "TRIAL",
        },
        {
          name: this.$t("periodicity.monthly"),
          value: "FLEX",
        },
        {
          name: this.$t("periodicity.annualPayment"),
          value: "ANNUAL",
        },
      ],
      SUBSCRIPTIONS,
    };
  },

  computed: {
    ...mapGetters([
      "selfCheckoutSubscriptionType",
      "currentUser",
      "mainDomain",
    ]),

    subscriptionOptions() {
      if (this.enableTrial) {
        return this.subscriptionsTypes;
      }
      return this.subscriptionsTypes.filter(
        (subscription) => subscription.value !== SUBSCRIPTIONS.TRIAL
      );
    },

    annualSelected() {
      return this.selfCheckoutSubscriptionType === SUBSCRIPTIONS.ANNUAL;
    },

    trialSelected() {
      return this.selfCheckoutSubscriptionType === SUBSCRIPTIONS.TRIAL;
    },

    subscriptionType: {
      get() {
        return this.selfCheckoutSubscriptionType;
      },
      set(value) {
        this.setSelfCheckoutSubscriptionType(value);
      },
    },
  },

  watch: {
    subscriptionType() {
      const analytics = getAnalytics();

      logEvent(analytics, "self-checkout", {
        click: "Troca de periodicidade (plano de pagamento)",
        main_domain: this.mainDomain,
        email: this.currentUser.email,
        description:
          "Troca de periodicidade (plano de pagamento) na etapa final da página de self-checkout",
      });
    },
  },

  methods: {
    ...mapMutations(["setSelfCheckoutSubscriptionType"]),
  },
};
</script>
