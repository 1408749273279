var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"elevation-0",attrs:{"flat":"","tile":""}},[_c('v-card-subtitle',{staticClass:"text-subtitle-2 text-center text-uppercase"},[(_vm.isNewPlan)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("selfCheckout.newPlan")))]):_c('span',[_vm._v(_vm._s(_vm.$t("common.currentPlan")))])]),_c('v-card',{style:(_vm.color
        ? `border: 1px solid var(--v-${_vm.color
            .replace('-', '')
            .replace(' ', '-')})`
        : ''),attrs:{"outlined":""}},[_c('v-toolbar',{staticClass:"mb-4 ma-0",attrs:{"dense":"","flat":"","color":_vm.color || 'grey lighten-3'}},[_c('v-toolbar-title',{staticClass:"text-body-1 font-weight-medium my-2 mx-auto"},[_vm._v(" "+_vm._s(_vm.companyPlan.plan?.name || _vm.selectedPlanName)+" ")])],1),_c('v-card-title',{staticClass:"font-weight-bold align-end text-h5 my-0 py-0"},[(
          [_vm.companyPlan.subscription_type, _vm.companyPlan.status].includes(
            _vm.SUBSCRIPTIONS.TRIAL
          )
        )?_c('span',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t("selfCheckout.trialPeriod"))+" ")]):_c('span',{staticClass:"mx-auto"},[_vm._v(" "+_vm._s(_vm.formatMoney(_vm.getTotalPrice(_vm.companyPlan)) || "0,00")),(_vm.companyPlan.subscription_type !== _vm.SUBSCRIPTIONS.FREE)?_c('span',{staticClass:"text-body-2 pb-1 ml-1"},[_vm._v("/ "+_vm._s(!!_vm.companyPlan.subscription_type ? _vm.$t(_vm.companyPlan.subscription_type) : _vm.$t(_vm.companyPlan.type)))]):_vm._e()])]),(
        (_vm.companyPlan.subscription_type === 'ANNUAL' ||
          _vm.companyPlan.type === 'ANNUAL') &&
        _vm.companyPlan.discountText
      )?_c('v-card-subtitle',{staticClass:"green--text caption font-weight-medium px-0 my-0 py-0 mx-auto"},[_vm._v(" "+_vm._s(`${_vm.$t("billing.discountOf")} ${_vm.companyPlan.discountText}% ${_vm.$t( "billing.fromOriginalPrice" )}`)+" ")]):_vm._e(),_c('v-card-subtitle',{staticClass:"text-body-2 font-weight-medium pa-0 mb-4 mt-2 text-center"},[(_vm.nextBilling)?_c('div',[_vm._v(" "+_vm._s(`${_vm.$t("common.from")} ${_vm.formatDateFullMonth(_vm.nextBilling)}`)+" ")]):(
          _vm.companyPlan.subscription_type === _vm.SUBSCRIPTIONS.TRIAL && !_vm.isNewPlan
        )?_c('div',[_vm._v(" "+_vm._s(`${_vm.$t("common.until")} ${_vm.formatDateFullMonth( _vm.companyPlan.end_trial )}`)+" ")]):_c('div',[_vm._v(" "+_vm._s(`${_vm.$t("common.since")} ${_vm.formatDateFullMonth( _vm.companyPlan.init_billing )}`)+" ")])]),_c('v-divider'),_c('div',{staticClass:"px-5"},[(_vm.benefits)?_c('BenefitsList',{attrs:{"benefits":_vm.benefits}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }