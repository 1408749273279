<template>
  <v-card flat tile class="elevation-0">
    <v-card-subtitle class="text-subtitle-2 text-center text-uppercase">
      <span v-if="isNewPlan"> {{ $t("selfCheckout.newPlan") }}</span>
      <span v-else>{{ $t("common.currentPlan") }}</span>
    </v-card-subtitle>
    <v-card
      outlined
      :style="
        color
          ? `border: 1px solid var(--v-${color
              .replace('-', '')
              .replace(' ', '-')})`
          : ''
      "
    >
      <v-toolbar
        dense
        flat
        :color="color || 'grey lighten-3'"
        class="mb-4 ma-0"
      >
        <v-toolbar-title class="text-body-1 font-weight-medium my-2 mx-auto">
          {{ companyPlan.plan?.name || selectedPlanName }}
        </v-toolbar-title>
      </v-toolbar>

      <v-card-title class="font-weight-bold align-end text-h5 my-0 py-0">
        <span
          v-if="
            [companyPlan.subscription_type, companyPlan.status].includes(
              SUBSCRIPTIONS.TRIAL
            )
          "
          class="text-uppercase"
        >
          {{ $t("selfCheckout.trialPeriod") }}
        </span>
        <span v-else class="mx-auto">
          {{ formatMoney(getTotalPrice(companyPlan)) || "0,00"
          }}<span
            class="text-body-2 pb-1 ml-1"
            v-if="companyPlan.subscription_type !== SUBSCRIPTIONS.FREE"
            >/
            {{
              !!companyPlan.subscription_type
                ? $t(companyPlan.subscription_type)
                : $t(companyPlan.type)
            }}</span
          >
        </span>
      </v-card-title>
      <v-card-subtitle
        v-if="
          (companyPlan.subscription_type === 'ANNUAL' ||
            companyPlan.type === 'ANNUAL') &&
          companyPlan.discountText
        "
        class="green--text caption font-weight-medium px-0 my-0 py-0 mx-auto"
      >
        {{
          `${$t("billing.discountOf")} ${companyPlan.discountText}% ${$t(
            "billing.fromOriginalPrice"
          )}`
        }}
      </v-card-subtitle>
      <v-card-subtitle
        class="text-body-2 font-weight-medium pa-0 mb-4 mt-2 text-center"
      >
        <!-- QUANDO PLANO FOR NOVO, EXIBIR A DATA EM QUE ELE COMEÇARÁ A SER PAGO -->
        <div v-if="nextBilling">
          {{ `${$t("common.from")} ${formatDateFullMonth(nextBilling)}` }}
        </div>
        <!-- SE O PLANO ATUAL FOR TRIAL. EXIBIR A DATA EM QUE ELE IRÁ ENCERRAR -->
        <div
          v-else-if="
            companyPlan.subscription_type === SUBSCRIPTIONS.TRIAL && !isNewPlan
          "
        >
          {{
            `${$t("common.until")} ${formatDateFullMonth(
              companyPlan.end_trial
            )}`
          }}
        </div>
        <!-- QUANDO UM PLANO ATUAL FOR ATIVO, EXIBIR A DATA EM QUE ELE COMEÇOU A SER PAGO -->
        <div v-else>
          {{
            `${$t("common.since")} ${formatDateFullMonth(
              companyPlan.init_billing
            )}`
          }}
        </div>
      </v-card-subtitle>
      <v-divider />
      <div class="px-5">
        <BenefitsList v-if="benefits" :benefits="benefits" />
      </div>
    </v-card>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import { formatMoney, formatDateFullMonth } from "@/helpers/services/utils";
import { getTotalPlanPrice } from "@/helpers/services/billing";
import { SUBSCRIPTIONS } from "@/helpers/variables/backendConstants.js";
import BenefitsList from "@/components/purchase/selfcheckout/BenefitsList.vue";

export default {
  name: "NewComparativePlan",

  components: { BenefitsList },

  props: {
    companyPlan: { type: Object, default: () => {} },
    nextBilling: { type: String, default: "" },
    isNewPlan: { type: Boolean, default: false },
    color: { type: String, default: "" },
    benefits: { type: Array, default: () => [] },
  },
  data() {
    return {
      getTotalPlanPrice,
      SUBSCRIPTIONS,
    };
  },
  computed: {
    ...mapGetters([
      "selectedPlanName",
      "usersNumber",
      "selectedResellerClient",
      "isSelfCheckout",
    ]),
  },
  methods: {
    formatMoney,
    formatDateFullMonth,

    getTotalPrice(plan) {
      if (plan.total_price) {
        return plan.total_price;
      }
      const isAnnualPlan =
        plan.subscription_type === "ANNUAL" || plan.type === "ANNUAL";

      let licenses = isAnnualPlan
        ? plan.max_licenses
        : this.isSelfCheckout
        ? this.usersNumber
        : this.selectedResellerClient.users_number;

      return this.getTotalPlanPrice(
        licenses,
        plan.subscription_type,
        plan.discount,
        plan.plan?.price
      );
    },
  },
};
</script>
