<template>
  <v-card
    :class="`text-center ${featured ? 'deep-purple lighten-5' : ''} ${
      (clientHasThisPlan && !isTrial) || plan.sku === isBasic
        ? 'light-green lighten-5'
        : ''
    }`"
    flat
    tile
    max-width="400"
    min-height="300"
  >
    <v-card-subtitle
      class="px-0 py-2 ma-0 text-uppercase font-weight-bold caption"
      style="height: 30px !important"
    >
      <div v-if="clientHasThisPlan && !isTrial" class="green--text">
        {{ $t("common.currentPlan") }}
      </div>

      <div v-if="clientHasThisPlan && isTrial" class="success--text">
        <v-icon v-text="'mdi-check-decagram'" color="success" size="20" />
      </div>

      <div
        v-else-if="featured && !clientHasThisPlan"
        class="d-flex align-center justify-center"
      >
        <v-icon
          small
          class="mr-1"
          color="accent"
          v-text="'mdi-star-circle-outline'"
        />
        <span class="accent--text text-lowercase">
          {{ $t("common.recommended") }}
        </span>
      </div>
      <div
        v-else-if="isBasic && !clientHasThisPlan"
        class="d-flex align-center justify-center"
      >
        <span class="success--text">
          {{ $t("common.free") }}
        </span>
      </div>
    </v-card-subtitle>

    <!-- PLAN NAME -->
    <v-card-title class="d-flex justify-center break-word pa-0 ma-0">
      <v-card-title class="d-block text-wrap text-truncate">
        {{ plan.name }}
      </v-card-title>
    </v-card-title>

    <v-list-item-title
      class="text-h4 mb-1 font-weight-bold primary--text text--lighten-1 text-uppercase"
    >
      {{ priceByPeriod }}
    </v-list-item-title>
    <v-list-item-subtitle class="">
      {{ period }}
    </v-list-item-subtitle>
    <v-list-item-subtitle
      class="text-body-2 mt-2 font-weight-medium secondary--text text--lighten-2"
    >
      ({{ totalPrice }}
      <span class="text-lowercase">{{ $t("common.to") }}</span>
      {{ updatedLicenses }} {{ $t("common.licenses") }})
    </v-list-item-subtitle>

    <v-expand-transition>
      <v-chip
        color="success"
        small
        class="font-weight-bold mx-auto mt-2"
        outlined
        v-if="discountInMoney"
      >
        {{ $t("hirePlan.economyOf") + " " + discountInMoney }}
      </v-chip>
      <v-chip
        color="white"
        small
        class="font-weight-bold mx-auto mt-2"
        v-if="isBasic && selfCheckoutSubscriptionType === 'ANNUAL'"
      >
      </v-chip>
    </v-expand-transition>

    <!-- SELECT PLAN BUTTON -->
    <v-card-text
      :class="`pb-0 pt-4 ${$vuetify.breakpoint.smAndDown ? 'px-6' : 'px-2'}`"
    >
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div v-on="endedTrialOrContratedPlan && on">
            <v-btn
              elevation="0"
              :large="$vuetify.breakpoint.smAndUp"
              :x-large="$vuetify.breakpoint.xs"
              :disabled="isAnnual || endedTrialOrContratedPlan"
              :color="
                clientHasThisPlan && !isTrial ? 'success' : 'accent lighten-1'
              "
              :outlined="!featured && !(clientHasThisPlan && !isTrial)"
              :class="`mb-4 mt-2 px-8 font-weight-bold text-none text-body-1`"
              @click="buyOrHiredHandler"
            >
              {{
                clientHasThisPlan && !isTrial
                  ? $t("common.seePlan")
                  : $t("action.buy")
              }}
            </v-btn>
          </div>
        </template>
        <span v-if="endedTrialOrContratedPlan">
          {{ $t("selfCheckout.hireBasicPlanRule") }}</span
        >
      </v-tooltip>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  getPriceByPeriod,
  getTotalPlanPrice,
  getTotalPlanPriceWithoutDiscount,
} from "@/helpers/services/billing";
import { translatePeriods } from "@/helpers/variables/translateString";
import {
  SUBSCRIPTIONS,
  STATUS,
  BASIC_SKU,
} from "@/helpers/variables/backendConstants.js";
import { formatMoney } from "@/helpers/services/utils";
import { FEATURED_SKU } from "@/helpers/variables/modules";
import { mapGetters } from "vuex";
export default {
  name: "UpgradePlanCard",

  components: {},

  props: {
    plan: { type: Object, required: true },
    clientHasThisPlan: { type: Boolean, default: false },
    subscriptionType: { type: String, required: true },
    licenses: { type: [Number, String], default: 0 },
  },

  data() {
    return {
      featured: this.plan.sku === FEATURED_SKU,
      SUBSCRIPTIONS,
      translatePeriods,
      STATUS,
      BASIC_SKU,
    };
  },

  computed: {
    ...mapGetters([
      "token",
      "clientActivePlans",
      "usersNumber",
      "selfCheckoutDiscountBySubscripton",
      "selfCheckoutMaxLicenses",
      "selfCheckoutSubscriptionType",
      "hasEndedTrial",
      "hasContractedPlans",
    ]),

    isBasic() {
      return this.plan.sku === BASIC_SKU;
    },

    endedTrialOrContratedPlan() {
      return !this.hasEndedTrial && this.isBasic && !this.hasContractedPlans;
    },

    planDetails() {
      return {
        key: this.plan.key,
        subscription_type: this.subscriptionType,
        price: this.planPrice,
        licenses: this.updatedLicenses,
        discount: this.selfCheckoutDiscountBySubscripton[this.subscriptionType],
      };
    },

    priceByPeriod() {
      return formatMoney(
        getPriceByPeriod(
          this.subscriptionType,
          this.planPrice,
          this.selfCheckoutDiscountBySubscripton[this.subscriptionType]
        )
      );
    },

    totalPrice() {
      if (this.planPrice > 0) {
        return formatMoney(
          getTotalPlanPrice(
            this.updatedLicenses,
            this.subscriptionType,
            this.selfCheckoutDiscountBySubscripton[this.subscriptionType],
            this.planPrice
          )
        );
      }
      return this.$t("common.free");
    },

    discountInMoney() {
      const discount =
        this.selfCheckoutDiscountBySubscripton[this.subscriptionType];

      if (discount > 0 && this.planPrice > 0) {
        const withoutDiscount = getTotalPlanPriceWithoutDiscount(
          this.updatedLicenses,
          this.subscriptionType,
          this.planPrice
        );

        const withDiscout = getTotalPlanPrice(
          this.updatedLicenses,
          this.subscriptionType,
          this.selfCheckoutDiscountBySubscripton[this.subscriptionType],
          this.planPrice
        );

        return formatMoney(
          parseFloat(withoutDiscount) - parseFloat(withDiscout)
        );
      }
      return false;
    },

    /**
     * Return a period based on subscription type, eg.: FLEX -> usuário/mês
     */
    period() {
      const period = {
        FLEX: this.$t("periodicity.month"),
        ANNUAL: this.$t("periodicity.year"),
      };

      return `${this.$t("common.user")}/${period[this.subscriptionType]}`;
    },

    planPrice() {
      return this.plan.price;
    },

    clientPlan() {
      if (this.clientHasThisPlan) {
        return this.clientActivePlans.find(
          (item) => item.plan.key === this.planKey
        );
      }
      return false;
    },

    isTrial() {
      return this.clientPlan ? this.clientPlan.status === STATUS.TRIAL : false;
    },

    clientPlanSubscriptioType() {
      return this.clientPlan ? this.clientPlan.subscriptionType : "";
    },

    isAnnual() {
      return this.clientPlanSubscriptioType === SUBSCRIPTIONS.ANNUAL;
    },

    rank() {
      return this.plan.rank;
    },

    planKey() {
      return this.plan.key;
    },

    updatedLicenses() {
      return this.licenses || this.selfCheckoutMaxLicenses || this.usersNumber;
    },
  },

  methods: {
    formatMoney,

    getPriceByPeriod,

    sendPlanInterest(plan) {
      const payload = {
        max_licenses: plan.licenses,
        subscription_type: plan.subscription_type,
        plan_name: this.plan.name,
      };
      const url =
        process.env.VUE_APP_API_BASE_URL + "/company/plans/plan-interest";

      const auth = {
        headers: { Authorization: this.token },
      };

      return this.$axios.post(url, payload, auth);
    },

    getClientPlan() {
      return this.clientActivePlans.find(
        (item) => item.plan.key === this.planKey
      );
    },

    buyOrHiredHandler() {
      if (this.clientHasThisPlan && !this.isTrial) {
        this.$router.push({
          name: "Company",
          query: { startingMenu: "plans_info" },
        });
      } else {
        this.$emit("select-plan", this.planDetails);
        this.sendPlanInterest(this.planDetails);
      }
    },
  },
};
</script>
<style scoped>
.card-border {
  border: 3px solid #eb5b1dc2 !important;
}
.current-plan-border {
  border-width: 3px !important;
  border-style: solid !important;
  border-color: var(--v-success-base) !important;
}
.plan-description {
  height: 60px !important;
}
</style>
